<template>
  <div>
    <Modal :title="title" v-model="imgStatus" label-position="left" width="900" :mask-closable="false" :footer-hide="true" class-name="vertical-center-modal" @on-visible-change="closeImg">
      <div class="imgDiv">
        <img :src="imgUrl" class="setsize" />
      </div>
      <div class="foot"></div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'tipsComponent',
  data() {
    return {
      imgStatus: true,
    }
  },
  props: {
    imgUrl: String,
    title: String,
  },
  mounted() {},
  methods: {
    closeImg(e) {
      this.$emit('closeImg', false)
    },
  },
}
</script>

<style scoped lang="less">
.imgDiv {
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
  }
}
</style>

<template>
  <div>
    <div class="tableTitle">
      <span class="title"> 经销协议 </span>
    </div>
    <!--  基础信息 -->
    <div class="fromDiv">
      <div class="formLine">
        <FromLabel label="经销协议" :fontColor="changeList.includes('agreement_file_name')"></FromLabel>
        <div class="allWidth">
          <Input class="iviewIptWidth307" placeholder="仅支持jpg.jepg.png.pdf等格式的图片/文件" v-model="postFrom.agreement_file_name" readonly>
            <span slot="append" class="finger">
              <FormUpload label="上传" @get-key="getKey" :data="data"></FormUpload>
            </span>
          </Input>
          <span class="lookupImg" v-if="postFrom.agreement_file_name" @click="openFile">{{ postFrom.agreement_file_name }}-经销协议</span>
          <span class="lookupImg" v-else>经销协议</span>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="签约日期" :fontColor="changeList.includes('signup_time')"></FromLabel>
        <div class="formFlIpt647 fl">
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth307" :value="postFrom.signup_time" @on-change="changeTime($event, 1)" :readonly="status != 3 && status != 2"></DatePicker>
        </div>
        <FromLabel label="有效期" :fontColor="changeList.includes('valid_period')"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth307" :value="postFrom.valid_period" :options="options" @on-change="changeTime($event, 2)" :readonly="status != 3 && status != 2"></DatePicker>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="账期" :showIcon="true" :fontColor="changeList.includes('payment_days')"></FromLabel>
        <div class="formFlIpt647 fl">
          <!--          <Select class="iviewIptWidth307" v-model="postFrom.payment_days" readonly>-->
          <!--            <Option v-for="(item, index) in periodList" :key="index" :value="item.value">{{ item.name }}</Option>-->
          <!--          </Select>-->
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="postFrom.payment_days" :readonly="status != 3 && status != 2"></i-input>
          <span class="afterWords10"> 天</span>
        </div>
        <FromLabel label="交易类型" :showIcon="true" :fontColor="changeList.includes('deal_type')"></FromLabel>
        <div class="formFrIpt fl">
          <Select class="iviewIptWidth307" v-model="postFrom.deal_type" :disabled="status != 3 && status != 2">
            <Option v-for="(item, index) in dealList" :key="index" :value="item.value">{{ item.name }}</Option>
          </Select>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="当地进院最低价" :fontColor="changeList.includes('lowest_price')"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model.trim="postFrom.lowest_price" :readonly="status != 3 && status != 2"></i-input>
          <span class="afterWords10"> 元</span>
        </div>
        <FromLabel label="当地指导价" :fontColor="changeList.includes('guide_price')"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model.trim="postFrom.guide_price" :readonly="status != 3 && status != 2"></i-input>
          <span class="afterWords10"> 元</span>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="当地省标价" :fontColor="changeList.includes('provincial_price')"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model.trim="postFrom.provincial_price" :readonly="status != 3 && status != 2"></i-input>
          <span class="afterWords10"> 元</span>
        </div>
        <FromLabel label="当地市标价" :fontColor="changeList.includes('market_price')"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model.trim="postFrom.market_price" :readonly="status != 3 && status != 2"></i-input>
          <span class="afterWords10"> 元</span>
        </div>
      </div>
      <div class="formLine">
        <FromLabel label="备注" :fontColor="changeList.includes('agreement_remark')"></FromLabel>
        <div class="allWidth">
          <i-input placeholder="请输入" class="iviewIptWidth1075" v-model.trim="postFrom.agreement_remark" :readonly="status != 3 && status != 2"></i-input>
        </div>
      </div>
      <div class="formLine">
        <FromLabel label="产品临床使用科室" :fontColor="changeList.includes('dept_id')"></FromLabel>
        <div class="allWidth">
          <Select class="iviewIptWidth1075" v-model="postFrom.dept_id" :disabled="status != 3 && status != 2" multiple>
            <Option v-for="(item, index) in deptList" :key="index" :value="item.dept_id">{{ item.dept_name }}</Option>
          </Select>
        </div>
      </div>
    </div>
    <!--    查看图片-->
    <lookup-image v-if="imgStatus && postFrom.agreement_file_url" @closeImg="imgStatus = false" title="经销协议" :imgUrl="postFrom.agreement_file_url" @></lookup-image>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
import LookupImage from '@/components/lookupImage'
import FormUpload from '@/components/formUpload'
export default {
  name: 'dealerInfo',
  components: {
    FromLabel,
    FormUpload,
    LookupImage,
  },
  data() {
    return {
      data: {},
      options: {},
      imgStatus: false,
      periodList: [],
      dealList: [
        {
          name: '普通购销',
          value: '1',
        },
        {
          name: '自寄售',
          value: '2',
        },
        {
          name: '供方寄售',
          value: '3',
        },
        {
          name: '委托代销',
          value: '4',
        },
      ],
      deptList: [],
      postFrom: {},
      status: '',
      count: 1,
      changeList: [],
    }
  },
  mounted() {
    if (this.$route.query.reviewId) {
      this.data = {
        product_review_id: this.$route.query.reviewId,
        table_type: 2,
      }
    } else {
      this.data = {
        table_type: 2,
      }
    }
    this.status = this.$route.query.status
    this.queryDept()
    for (let i = 0; i < 12; i++) {
      this.periodList.push({
        name: `${i + 1}个月`,
        value: i + 1,
      })
    }
    this.$root.$on('productInfo', res => {
      let detali = res
      if (this.count == 1) {
        this.changeList = res.changeList || []
        this.postFrom = {
          agreement_file_key: detali.agreement_file_key,
          agreement_file_name: detali.agreement_file_name,
          agreement_file_url: detali.agreement_file_url,
          signup_time: detali.signup_time ? this.$moment(detali.signup_time * 1000).format('YYYY-MM-DD') : '',
          valid_period: detali.valid_period ? this.$moment(detali.valid_period * 1000).format('YYYY-MM-DD') : '',
          payment_days: detali.payment_days,
          deal_type: detali.deal_type + '', // 1 普通购销  2 自寄售  3 供方寄售  4 委托代销
          lowest_price: detali.lowest_price,
          guide_price: detali.guide_price,
          provincial_price: detali.provincial_price,
          market_price: detali.market_price,
          agreement_remark: detali.agreement_remark,
          dept_id: detali.dept_id,
        }
        this.count++
      }
    })
  },
  methods: {
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.postFrom.signup_time = e
        that.postFrom.valid_period = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.postFrom.signup_time)
          },
        }
      } else {
        this.postFrom.valid_period = e
      }
    },
    // 上传注册证
    getKey(key, name, url) {
      this.postFrom.agreement_file_key = key
      this.postFrom.agreement_file_name = name
      this.postFrom.agreement_file_url = url
    },
    openFile() {
      window.open(this.postFrom.agreement_file_url)
    },
    // 查询科室下拉
    queryDept() {
      this.$http.get(this.$api.productSearchDept, {}, true).then(res => {
        this.deptList = res.data
      })
    },
  },
}
</script>

<style scoped lang="less">
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
.tableTitle {
  margin: 17px 0 20px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
.centerUnit {
  margin: 0px 10px 0px 30px !important;
}
.imgBox {
  min-height: 110px;
  ul {
    height: 100%;
    width: 100%;
    padding: 0;
    li {
      position: relative;
      margin-right: 18px;
      display: inline-block;
      height: 102px;
      width: 171px;
      background-color: #d8d8d8;
      .normalImg {
        height: 100%;
        width: 100%;
      }
      .deleteImg {
        height: 22px;
        width: 22px;
        position: absolute;
        right: -11px;
        top: -11px;
        cursor: pointer;
      }
    }
  }
}
.addImg,
.removeImg {
  height: 22px;
  width: 22px;
  cursor: pointer;
}
.addImg {
  margin: 0 14px 0 36px;
}
</style>
